<template>
  <div class="dialog">
    <div class="form-input-bloc">
      <h3>{{ $t('match.refuse_reason') }}</h3>
      <div
        class="reason"
        v-for="(reason, i) in getLists.refuse_match_reasons"
        :key="reason.value"
      >
        <input
          type="checkbox"
          :id="'reason_' + reason.value"
          :value="reason.value"
          v-model="selectedReasons[i]"
        />
        <label :for="'reason_' + reason.value">{{ reason.label }}</label>
      </div>
    </div>
    <div class="form-input-bloc">
      <h3>{{ $t('search.empty.description') }}</h3>
      <el-input
        v-model="description"
        :autosize="{ minRows: 4, maxRows: 4 }"
        type="textarea"
        :placeholder="$t('search.empty.description_placeholder_match')"
      />
    </div>
    <div class="buttons">
      <button
        :style="{ backgroundColor: isButtonDisabled ? 'grey' : '#2667FF' }"
        :disabled="isButtonDisabled"
        class="button generic-btn"
        @click="refuseMatch"
      >
        {{ $t('match.refuse') }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  props: {
    match_uuid: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      description: null,
      selectedReasons: [],
    }
  },
  computed: {
    ...mapGetters({
      getLists: 'getLists',
    }),
    isButtonDisabled() {
      return (
        this.selectedReasons.length === 0 ||
        this.selectedReasons.every((reason) => !reason)
      )
    },
  },
  methods: {
    ...mapActions({
      sendDeclineMatch: 'refuseMatch',
    }),
    closeDialog() {
      this.$emit('close')
    },
    async refuseMatch() {
      const payload = {
        pretrade_uuid: this.match_uuid,
        reasons: [],
        description: this.description,
      }

      for (const key in this.selectedReasons) {
        if (this.selectedReasons[key] === true) {
          payload.reasons.push(Number([key][0]))
        }
      }

      await this.sendDeclineMatch(payload).then(() => {
        this.reasons = []
        this.selectedReasons = []
        this.closeDialog()
        this.$router.push('/auth/order-list')
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/root.scss';

.button {
  width: 194px;
  margin: auto;
}
.dialog {
  border-radius: 5px;
}

.reasons {
  margin-bottom: 20px;
}

.reason {
  margin-bottom: 10px;
  font-size: 14px;
  color: var(--text-grey);
}

.reason input {
  margin-right: 10px;
}

.buttons {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.form-input-bloc {
  h3 {
    color: var(--text-color);
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 8px;
    margin-top: 5px;

    span {
      color: var(--text-grey);
      font-size: 15px;
      font-weight: 500;
    }
  }
}
</style>
